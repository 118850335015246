import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'

import Title from '../shared/Title'
import { Container, Row, Col, Badge } from 'reactstrap'
// import icons
import { FaTwitter } from 'react-icons/fa'
import { FaFacebook } from 'react-icons/fa'
import { FaGooglePlus } from 'react-icons/fa'
import { FaEnvelope } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'

// import react-custom-share components
import { ShareButtonRoundSquare, ShareBlockStandard } from 'react-custom-share'

class BlogSection extends React.Component {
  render() {
    const shareBlockProps = {
      button: ShareButtonRoundSquare,
      buttons: [
        { network: 'Twitter', icon: FaTwitter },
        { network: 'Facebook', icon: FaFacebook },
        { network: 'GooglePlus', icon: FaGooglePlus },
        { network: 'Email', icon: FaEnvelope },
        { network: 'Linkedin', icon: FaLinkedin },
      ],
    }

    return (
      <StaticQuery
        query={graphql`
          {
            data: contentfulIndexPage {
              blogPosts {
                id
                title
                description
                slug
                published(formatString: "MMMM Do, YYYY")
                excerpt {
                  text: excerpt
                }
                featuredImage {
                  title
                  description
                  file {
                    url
                  }
                }
              }
            }
          }
        `}
        render={({ data }) => (
          <div>
            <Container>
              {data.blogPosts.length > 1 &&
                data.blogPosts[0].title !== 'PLACEHOLDER' && (
                  <h2 className=" my-5 text-center text-primary">
                    Recent Blogs
                  </h2>
                )}

              {data.blogPosts.map(
                (node, i, array) =>
                  array.length == 1 &&
                  node.title == 'PLACEHOLDER' && (
                    <div className="py-3 my-5">
                      No blog post currently available.
                    </div>
                  )
              )}
              {data.blogPosts.map(
                (node, i, array) =>
                  node.title !== 'PLACEHOLDER' && (
                    <Row
                      className="rounded py-3 my-5 bg-gradient"
                      style={{
                        maxWidth: '100%',
                        margin: '0 auto',
                        background: 'url("/images/dotted-map.png")',
                        backgroundColor: '#171B26',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                      }}
                      key={i}
                    >
                      <Col md="4" className="position-relative  ">
                        <Link to={`blog/${node.slug}`}>
                          <div
                            className="w-100 border-40"
                            title={node.featuredImage.title}
                            style={{
                              height: '15em',
                              maxHeight: '100%',
                              margin: '0 auto 1em auto',
                              background: `url(${node.featuredImage.file.url})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center center',
                              backgroundRepeat: 'no-repeat',
                            }}
                          />
                        </Link>
                        <Badge
                          color="primary"
                          className="text-white w-75 d-block py-2  mb-2 mx-auto  "
                        >
                          {node.published}
                        </Badge>
                      </Col>
                      <Col md="8" className="pt-3">
                        <div>
                          <h3 className="text-gold">
                            <Title to={`blog/${node.slug}`}>{node.title}</Title>
                          </h3>
                          <p className="text-white">{node.excerpt.text}</p>
                          <Link
                            to={`blog/${node.slug}`}
                            className="btn primary-btn-gradient text-white py-2 px-5"
                          >
                            Read More
                          </Link>
                          <ShareBlockStandard
                            className="blog-listing-shares"
                            {...shareBlockProps}
                            url={`https://routemagic.co.uk/blog/${node.slug}`}
                            text={node.title}
                            longtext={node.excerpt.text}
                          />
                        </div>
                      </Col>
                    </Row>
                  )
              )}
            </Container>
          </div>
        )}
      />
    )
  }
}

export default BlogSection
