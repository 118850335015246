import Max from 'lodash/max'

const spacerHelper = cls => {
  cls.forEach(cl => {
    if (window.innerWidth > 576) {
      let spacers = document.querySelectorAll(cl)
      let heights = []
      spacers.forEach(el => heights.push(el.clientHeight))
      spacers.forEach(el => {
        el.style.height = `${Max(heights)}px`
      })
    } else {
      let spacers = document.querySelectorAll(cl)
      spacers.forEach(el => {
        el.style.height = ``
      })
    }
  })
}

export default spacerHelper
