import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import HeroHome from '../components/homePage/HeroHome'
import Features from '../components/homePage/Features'
import Products from '../components/homePage/Products'
import Testimony from '../components/homePage/Testimony'
import BlogSection from '../components/homePage/BlogSection'
import Icons from '../components/shared/Icons'

import Seo from '../components/shared/Seo'

const IndexPage = props => {
  const { pageTitle, description, keywords } = props.data.seo
  return (
    <Layout>
      <Seo title={pageTitle} description={description} keywords={keywords} />
      <HeroHome />
      <Features />
      <Products />
      <Testimony />
      <div className="py-3" />
      <BlogSection />
      <Icons />
    </Layout>
  )
}
export default IndexPage

export const query = graphql`
  {
    seo: contentfulIndexPage {
      pageTitle
      description
      keywords
    }
  }
`
