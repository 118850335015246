import React, { Component } from 'react'
import Spacer from '../spacerHelper'

import { StaticQuery, graphql, Link } from 'gatsby'

import { Container, Row, Col } from 'reactstrap'

export default class Testimony extends Component {
  componentDidMount() {
    Spacer(['.spacer'])
    window !== undefined &&
      window.addEventListener('resize', Spacer(['.spacer']))
  }
  componentWillUnmount() {
    window !== undefined && window.removeEventListener('resize', Spacer)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            data: contentfulIndexPage {
              reviewsTitle
              reviews {
                author
                text {
                  text
                }
                link
                image {
                  title
                  description
                  file {
                    url
                  }
                }
              }
            }
          }
        `}
        render={({ data }) => (
          <Container className="py-5">
            <Link
              style={{ textDecoration: 'none' }}
              to="/customers/#our-customers"
            >
              {' '}
              <h2 className="text-primary text-center">{data.reviewsTitle}</h2>
            </Link>{' '}
            <Row>
              {data.reviews.map((node, i) => (
                <Col sm="4" className="d-flex flex-column" key={i}>
                  <Link
                    to={node.link}
                    style={{ textDecoration: 'none', color: '#212529' }}
                  >
                    <div
                      className=" mx-auto d-flex align-items-center"
                      style={{ width: '33%' }}
                    >
                      <div
                        title={node.image.title}
                        style={{
                          background: `url(${node.image.file.url})`,
                          width: '100%',
                          paddingBottom: '100%',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: '50% 50%',
                          backgroundSize: 'contain',
                        }}
                      />
                    </div>
                    <p className="spacer text-justify">{node.text.text}</p>
                  </Link>
                  <div
                    style={{
                      borderTop: '1px solid #979797',
                      paddingTop: '1em',
                    }}
                  >
                    <h3 className="text-primary text-center">{node.author}</h3>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        )}
      />
    )
  }
}
