import React from 'react'
import classnames from 'classnames'

const Image = props => (
  <div
    className={classnames(
      'img-fluid d-block mx-auto border-40',
      props.className
    )}
    title={props.title}
    alt={props.alt}
    style={{
      background: `url(${props.src})`,
      backgroundPosition: props.position ? props.position : 'center center',
      backgroundSize: props.size,
      backgroundRepeat: 'no-repeat',
      paddingBottom: props.pb,
    }}
  />
)

export default Image
