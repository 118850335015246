import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import { Container, Button, Row, Col } from 'reactstrap'
import Image from '../shared/Image'
import Spacer from '../spacerHelper'
import styled from 'styled-components'
const Text = styled.div`
  & em {
    font-style: normal;
    font-size: 0.777em;
    line-height: 1.2;
  }
`
export default class Products extends Component {
  constructor(props) {
    super(props)
    this.state = {
      input: '',
    }
  }
  componentDidMount() {
    Spacer(['.spacer-product-title', '.spacer-product-text'])
    window !== undefined &&
      window.addEventListener(
        'resize',
        Spacer(['.spacer-product-title', '.spacer-product-text'])
      )
  }
  componentWillUnmount() {
    window !== undefined && window.removeEventListener('resize', Spacer)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            data: contentfulIndexPage {
              id

              productTypes {
                id
                title
                text {
                  g: childMarkdownRemark {
                    html
                  }
                }
                link
                image {
                  title
                  description
                  file {
                    url
                  }
                }
              }
            }
          }
        `}
        render={({ data }) => {
          return (
            <div className="bg-dark text-white ">
              <Container className="py-5 ">
                <Row>
                  {data.productTypes.map((product, index) => {
                    return (
                      <Col lg="3" sm="6" key={index}>
                        <Link
                          to={product.link}
                          title={product.title}
                          style={{ textDecoration: 'none' }}
                        >
                          <h3 className="text-gold text-center mb-3 h3 spacer-product-title">
                            {product.title}
                          </h3>
                          <Image
                            className="mb-3"
                            src={product.image.file.url}
                            alt={product.image.description}
                            pb="100%"
                            size="cover"
                          />
                        </Link>
                        <Text
                          className="spacer-product-text text-justify"
                          dangerouslySetInnerHTML={{
                            __html: product.text.g.html,
                          }}
                        />
                        <div className="text-center">
                          {' '}
                          <Button
                            tag={Link}
                            to={product.link}
                            color="primary"
                            className="py-2 mt-md-3 mb-md-0 mt-0 mb-5 px-5"
                          >
                            Know More
                          </Button>
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </Container>
            </div>
          )
        }}
      />
    )
  }
}
