import React, { Component } from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'

import { Container, Button, Input, Row, Col } from 'reactstrap'
import Youtube from '../shared/Youtube'
import Legal from '../shared/Legal'
import Spacer from '../spacerHelper'

export default class HeroHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      input: '',
    }
  }
  componentDidMount() {
    Spacer(['.spacer'])
    window !== undefined &&
      window.addEventListener('resize', Spacer(['.spacer-card-title']))
  }
  componentWillUnmount() {
    window !== undefined && window.removeEventListener('resize', Spacer)
  }

  render() {
    return (
      <div className="bg-dark">
        <Container className="py-3 ">
          <StaticQuery
            query={graphql`
              {
                globals: contentfulGlobal {
                  buttonLegal {
                    id
                    g: childMarkdownRemark {
                      html
                    }
                  }
                }
                index: contentfulIndexPage {
                  id
                  youtubeVideoId
                  heroTitle
                }
              }
            `}
            render={({ index, globals }) => {
              return (
                <div>
                  <h1 className="text-gold text-center py-2 mb-4">
                    {index.heroTitle}
                  </h1>
                  <div className="w-75 mx-auto">
                    <Youtube videoID={index.youtubeVideoId} />
                  </div>

                  {/* FORM */}
                  <Row className="my-5 justify-content-center">
                    <Col sm="6" md="4" lg="3">
                      <Input
                        type="email"
                        placeholder="Enter your email"
                        style={{ height: '60px' }}
                        onChange={e => {
                          this.setState({ input: e.target.value })
                        }}
                        value={this.state.input}
                        className="d-flex text-center align-middle white-btn-gradient w-100"
                      />
                      <Legal dark className="text-center" />
                    </Col>

                    <Col sm="6" md="4" lg="3" className="text-center">
                      <Button
                        color="primary"
                        style={{ height: '60px', lineHeight: 1 }}
                        size="lg"
                        onClick={e => {
                          e.preventDefault()
                          navigate('/contact', {
                            state: {
                              selected: 'Contact Us',
                              email: this.state.input,
                            },
                          })
                        }}
                        className="w-100 text-white py-3  text-center primary-btn-gradient "
                      >
                        {' '}
                        Get Started
                      </Button>
                    </Col>
                  </Row>
                </div>
              )
            }}
          />
        </Container>
      </div>
    )
  }
}
