import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import { Container, Row, Col } from 'reactstrap'
import Image from '../shared/Image'
import Spacer from '../spacerHelper'

export default class Products extends Component {
  constructor(props) {
    super(props)
    this.state = {
      input: '',
    }
  }
  componentDidMount() {
    Spacer(['.spacer'])
    window !== undefined &&
      window.addEventListener('resize', Spacer(['.spacer-feature-title']))
  }
  componentWillUnmount() {
    window !== undefined && window.removeEventListener('resize', Spacer)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            data: contentfulIndexPage {
              id

              features {
                id
                title
                text {
                  g: childMarkdownRemark {
                    html
                  }
                }
                link
                image {
                  title
                  description
                  file {
                    url
                  }
                }
              }
            }
          }
        `}
        render={({ data }) => {
          return (
            <div className=" ">
              <Container className="py-5 ">
                <Row>
                  {data.features.map((feature, index) => {
                    return (
                      <Col lg="3" sm="6" key={index}>
                        <Link
                          to={feature.link}
                          title={feature.title}
                          style={{ textDecoration: 'none' }}
                        >
                          <h3 className="text-dark text-center mb-3 h3 spacer-feature-title">
                            {feature.title}
                          </h3>
                          <Image
                            className="mb-3"
                            src={feature.image.file.url}
                            alt={feature.image.description}
                            pb="100%"
                            size="cover"
                          />
                        </Link>
                        <div
                          className="text-justify"
                          dangerouslySetInnerHTML={{
                            __html: feature.text.g.html,
                          }}
                        />
                      </Col>
                    )
                  })}
                </Row>
              </Container>
            </div>
          )
        }}
      />
    )
  }
}
