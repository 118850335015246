import React, { Component } from 'react'
import { Link } from 'gatsby'

export default class Title extends Component {
  render() {
    return (
      <Link
        to={this.props.to}
        style={{
          cursor: 'pointer',
          textDecoration: 'none',
          color: 'inherit',
        }}
        title={this.props.title}
      >
        {this.props.children}
      </Link>
    )
  }
}
